import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IClassesReponse } from '@modules/classes/services/ListClassesService';
import IListOptions from '@shared/interfaces/IListOptions';

interface IListClassesUserManageOptions extends IListOptions {
  shifts?: string[];
  types?: string[];
  age_groups?: string[];
  places_ids?: number[];
  group_ids?: number[];
  teacher_id?: number[] | false;
  lms_user_id?: number[] | false
}

class ListClassesUserManagesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListClassesUserManageOptions): Promise<IClassesReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery(
        {
          query,
          ...options,
        },
        true
      );

      const res = await this.api.get(`/class/manage?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListClassesUserManagesService;
