import { ISelectOption } from 'react-alicerce-components';

export const CLASS_MODALITY_QUALIFY = { name: 'Qualifica', value: 'qualify' };
export const CLASS_MODALITY_REINFORCE = { name: 'Reforço', value: 'reinforce' };
export const MODALITY_ALICERCINHO = { name: 'Alicercinho', value: 'alicercinho' };

export type IClassValidModalities = 'qualifies' | 'school_support' | 'alicercinho';

export const CLASS_MODALITY_CONSTRAINTS = [CLASS_MODALITY_QUALIFY, CLASS_MODALITY_REINFORCE, MODALITY_ALICERCINHO];

export const defaultModality = (classModality?: string | null): ISelectOption[] | undefined => {
  return CLASS_MODALITY_CONSTRAINTS.map((m) => {
    return { name: m.name, value: m.value, selected: m.value === classModality };
  });
};
