import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IClass from '@modules/classes/interfaces/IClass';
import IListOptions from '../../../shared/interfaces/IListOptions';

export interface IClassesReponse extends IPaginatedResponse {
  items: IClass[];
}

interface IListClassesOptions extends IListOptions {
  age_groups?: string[];
  status?: string[];
  types?: string[];
  group_id?: string;
  student_id?: string;
  teacher_id?: string | number;
  lms_user_id?: number[] | false
  fixed?: boolean;
  favorited?: boolean;
}

class ListClassesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListClassesOptions): Promise<IClassesReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, order: 'class.name', ...options }, true);

      const res = await this.api.get(`/class?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListClassesService;
