import { ISelectOption } from 'react-alicerce-components';

export const TYPE_CORE = { name: 'Core', value: 'core' };
export const TYPE_ENGLISH = { name: 'Inglês', value: 'english' };
export const TYPE_EXPERIMENTAL_CLASS = { name: 'Aula Experimental', value: 'trial' };
export const TYPE_CLUB = { name: 'Clube', value: 'club' };
export const TYPE_PREP_COURSE = { name: 'Curso Preparatório', value: 'prep_course' };
export const TYPE_COMPLETE = { name: 'Completo', value: 'complete' };
export const TYPE_APPRENTICE = { name: 'Aprendiz', value: 'apprentice' };

export type IClassValidTypes = 'core' | 'english' | 'experimental_class' | 'club' | 'prep_course' | 'complete' | 'apprentice';

export const CLASS_TYPE_CONSTRAINTS = [TYPE_CORE, TYPE_ENGLISH, TYPE_EXPERIMENTAL_CLASS, TYPE_CLUB, TYPE_PREP_COURSE, TYPE_COMPLETE, TYPE_APPRENTICE];

export const defaultType = (classType?: string | null): ISelectOption[] | undefined => {
  return CLASS_TYPE_CONSTRAINTS.map((t) => {
    return {
      name: t.name,
      value: t.value,
      selected: classType === t.value,
    };
  });
};
