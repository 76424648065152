import { IUpdateTeachersStudentsForm } from '../context/ClassContext';
import IClass from '../interfaces/IClass';

const formatDefaultFields = (classCurrent: IClass) => {
  const formattedCalendarData = classCurrent.calendar_data?.map((calendar) => {
    return {
      start: calendar.start,
      end: calendar.end,
      break: calendar.break ? calendar.break : '00:00',
      shift: calendar.shift,
      week_days: calendar.week_days,
    };
  });

  return {
    name: classCurrent.name,
    type: classCurrent.type,
    modality: classCurrent.modality === null ? undefined : classCurrent.modality,
    isCustomClass: classCurrent.isCustomClass,
    customization: classCurrent.customization,
    age_groups: classCurrent.age_groups,
    start_date: classCurrent.start_date,
    calendar_data: formattedCalendarData,
    status: classCurrent.status,
    group_id: classCurrent.group ? classCurrent.group.id : null,
    place_id: classCurrent.place ? classCurrent.place.id : null,
    tag_ids: classCurrent.tags ? classCurrent.tags?.map((t) => Number(t.id)) : [],
  };
};

export const formatUpdateClass = (classCurrent: IClass & IUpdateTeachersStudentsForm) => {
  return {
    ...formatDefaultFields(classCurrent),
    teachers_change: classCurrent.update_teachers || {},
    students_change: classCurrent.update_students || {},
  };
};

export const formatClass = (classCurrent: IClass) => {
  return {
    ...formatDefaultFields(classCurrent),
    teachers_change: { enroll: classCurrent.teachers?.map((t) => Number(t.id)) ?? undefined },
    students_change: { enrolL: classCurrent.enrollments?.flatMap((e) => e.student_id ?? []) },
    expected_students: classCurrent.expected_students,
  };
};
