import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, H6, Pagination, Spinner, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from './styles';
import Icon from 'react-eva-icons';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import CustomForm from '@shared/components/CustomForm';
import PageFooter from '@shared/components/PageFooter';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';

import ListClassesUserManagesService from '@modules/classes/services/ListClassesUserManagesService';
import { CreateEnrollmentService } from '@modules/subscriptions/services/CreateEnrollmentService';
import { ClassCardEnrollment } from '@modules/subscriptions/components/ClassCardEnrollment';
import { SubscriptionCard } from '@modules/subscriptions/components/SubscriptionCard';
import { useSubscription } from '@modules/subscriptions/context/SubscriptionContext';
import { formatEnrollment } from '@modules/subscriptions/utils/formatEnrollment';
import { IClassesReponse } from '@modules/classes/services/ListClassesService';
import { IEnrollment } from '@modules/subscriptions/interfaces/IEnrollment';
import IClass from '@modules/classes/interfaces/IClass';
import { SubscriptionsAdvancedFilter } from '../../components/SubscriptionsAdvancedFilter';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const EnrollmentSubscriptions: React.FC = () => {
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const groupInPath = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { newCancelToken } = useCancelRequest();
  const [classes, setClasses] = useState<IClassesReponse>();
  const [enrollment, setEnrollment] = useState<IEnrollment>();
  const [showEnrollmentModal, setShowEnrollmentModal] = useState<boolean>(false);
  const [selectElement, setSelectElement] = useState<number>(-1);

  const isMounted = useIsMounted();
  const { checkedSubscriptions, setCheckedSubscriptions, subscriptionsFilter } = useSubscription();

  const pathToReturn = useMemo(() => {
    return `/assinaturas/grupo/${groupInPath.id}/pendentes`;
  }, [groupInPath.id]);

  const removeSubscriptionCard = useCallback(
    (studentId?: number) => {
      const newStudents = checkedSubscriptions.filter((s) => s.id !== studentId);
      setCheckedSubscriptions(newStudents);
      if (newStudents.length === 0) {
        addToast({
          status: 'warning',
          title: 'Enturmamento',
          description: 'Nenhum aluno selecionado. Retornando para seleção de alunos',
        });
        return navigate(pathToReturn);
      }
    },
    [addToast, checkedSubscriptions, navigate, pathToReturn, setCheckedSubscriptions]
  );

  const listClassesUserManages = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListClassesUserManagesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        ...subscriptionsFilter.current,
        group_ids: [Number(groupInPath.id)],
        teacher_id: false,
      });
      if (res === 'canceling') return;
      if (!isMounted()) return;
      setIsLoading(false);
      if (res) {
        setClasses(res);
      }
    },
    [signedUser, setIsLoading, newCancelToken, subscriptionsFilter, groupInPath.id, isMounted]
  );

  useEffect(() => {
    listClassesUserManages();
  }, [listClassesUserManages, subscriptionsFilter]);

  const handleCreateEnrollment = useCallback(async () => {
    if (!signedUser) return;
    setIsLoading(true);
    setShowEnrollmentModal(false);
    const formatedEnrollment = formatEnrollment(checkedSubscriptions, enrollment?.class);
    const res = await new CreateEnrollmentService(signedUser.token).execute(formatedEnrollment);
    setIsLoading(false);
    if (!res) {
      return addToast({
        status: 'danger',
        title: 'Enturmamento',
        description: `Ocorreu um erro ao criar o enturmamento(s).`,
      });
    } else if (!!res?.errors?.length) {
      return addToast({
        status: 'danger',
        title: 'Enturmamento',
        description: `Ocorreu um erro ao criar o enturmamento(s).`,
      });
    } else {
      addToast({
        status: 'success',
        title: 'Enturmamento',
        description: 'Enturmamento concluído com sucesso.',
      });
      setCheckedSubscriptions([]);
      navigate(pathToReturn);
    }
  }, [signedUser, setIsLoading, checkedSubscriptions, enrollment, addToast, setCheckedSubscriptions, navigate, pathToReturn]);

  const handleOnClickSelectClass = (classData: IClass) => {
    setSelectElement(Number(classData.id!));
    setEnrollment((oldState) => {
      return { ...oldState, class: classData };
    });
  };

  useEffect(() => {
    return function cleanup() {
      subscriptionsFilter.current = {};
    };
  }, [subscriptionsFilter]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Enturmamento" pathToReturn={pathToReturn} status="quaternary" menuKebab={{ menuBackHome: true }} />

      <DirectoryContainer footer={true}>
        <CustomForm>
          <div className="container-enrollment-subscription-cards">
            {checkedSubscriptions.map((sub, index) => (
              <SubscriptionCard
                key={index}
                subscription={sub}
                className="subscription-card"
                canDeleteCard={{ indexId: index, removeCallback: () => removeSubscriptionCard(Number(sub.id)) }}
                trails
              />
            ))}
          </div>

          <Divider width="100%" />
          <H6 fontWeight={800} status="quaternary" textAlign="center" marginBottom={'2rem'}>
            TURMAS DISPONÍVEIS
          </H6>

          <SubscriptionsAdvancedFilter handleOnChangeMainInput={listClassesUserManages} handleOnClickApplyFilterCallback={listClassesUserManages} />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {classes?.items.map((c) => (
              <ClassCardEnrollment
                key={c.id}
                classData={c}
                className="subscription-card"
                trails
                onClick={(event) => handleOnClickSelectClass(event)}
                selectElement={selectElement}
              />
            ))}
          </div>

          {classes && (
            <Pagination
              status="quaternary"
              totalOfPages={classes?.totalPages}
              currentPage={classes?.currentPage}
              callbackGetListData={listClassesUserManages}
            />
          )}

          <PageFooter>
            <Button onClick={() => navigate(pathToReturn)} status="quaternary" transform="none" shaded outline className="button-cancel">
              Cancelar
            </Button>
            <Button onClick={() => setShowEnrollmentModal(true)} status="quaternary" transform="none" noBorder shaded disabled={!!!enrollment?.class}>
              Enturmar
            </Button>
          </PageFooter>
        </CustomForm>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEnrollmentModal}
        closeCallback={() => setShowEnrollmentModal(false)}
        title="Confirma o Enturmamento Destes Alunos?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateEnrollment}
      />
    </Container>
  );
};

export { EnrollmentSubscriptions };
