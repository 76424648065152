import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Button, Checkbox, Label, Pagination, Spinner, useToast } from 'react-alicerce-components';
import { WrapperContent } from './styles';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router';
import ListClassesService from '@modules/classes/services/ListClassesService';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ClassCard from '@modules/classes/components/ClassCard';
import IClass from '@modules/classes/interfaces/IClass';
import { useClass } from '@modules/classes/context/ClassContext';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { CLASS_TYPE_CONSTRAINTS } from '@modules/classes/utils/validData/validClassType';
import { CLASS_STATUS_CONSTRAINTS } from '@modules/classes/utils/validData/validClassStatus';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { BsCheckLg } from 'react-icons/bs';
import Divider from '@shared/components/Divider';
import { CLASS_MODALITY_CONSTRAINTS } from '@modules/classes/utils/validData/validClassModality';

interface IClassesList {
  headerTitle: string;
  pathToReturn?: string;
  btnText: string;
  handleOnClick?: (selectedClass: any) => void;
  canSelect?: 'one' | 'many';
  selectedClass?: IClass[];
  setSelectedClass?: (selectedClass: IClass[]) => void;
  pathRedirect?: string;
}

type IClassFilter = {
  age_groups?: string[];
  status?: string[];
  types?: string[];
};

const ClassesList: React.FC<IClassesList> = (props) => {
  const { clearClassContextData, setPathToReturn } = useClass();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const navigate = useNavigate();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const [classes, setClasses] = useState<IClass[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalOfPages, setTotalOfPages] = useState<number>(0);
  const [inputSearch, setInputSearch] = useState<string>('');
  const classFilter = useRef<IClassFilter>({});

  const listClasses = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListClassesService(signedUser.token, newCancelToken()).execute(inputSearch, { page, size: 10, ...classFilter.current });
      if (res === 'canceling') return;
      setIsLoading(false);

      if (!res) {
        addToast({
          status: 'danger',
          title: 'Turmas',
          description: 'Error ao listar turmas.',
        });
        return;
      }

      setClasses(res.items);
      setCurrentPage(res.currentPage);
      setTotalOfPages(res.totalPages);
    },
    [addToast, inputSearch, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listClasses();
  }, [listClasses]);

  useEffect(() => {
    setPathToReturn('/diretorio/turmas');
  }, [setPathToReturn]);

  const handleClickBtn = useCallback(() => {
    if (props.canSelect === 'one' && props.selectedClass) {
      props.handleOnClick && props.handleOnClick(props.selectedClass[0]);
    } else if (props.canSelect === 'many' && props.selectedClass) {
      props.handleOnClick && props.handleOnClick(props.selectedClass);
    } else clearClassContextData();
    props.pathRedirect ? navigate(props.pathRedirect) : navigate(-1);
  }, [props, clearClassContextData, navigate]);

  const checkSelectedClasses = useCallback((selectedItens: IClass[], clickedItem: IClass): false | IClass[] => {
    const found = selectedItens.findIndex((p) => p.id === clickedItem.id);
    if (found === -1) return false;
    selectedItens.splice(found, 1);
    return selectedItens;
  }, []);

  const handleClickClassCard = useCallback(
    (classClicked: IClass) => {
      if (!props.canSelect) {
        navigate(`/turma/${classClicked.id}`);
      }
      if (props.canSelect === 'one' && props.selectedClass && props.setSelectedClass) {
        if (props.selectedClass[0]?.id === classClicked.id) return props.setSelectedClass([]);
        const aux = [] as IClass[];
        aux.push(classClicked);
        props.setSelectedClass(aux);
      } else if (props.canSelect === 'many' && props.selectedClass && props.setSelectedClass) {
        const res = checkSelectedClasses(props.selectedClass, classClicked);
        if (!res) return props.setSelectedClass([...props.selectedClass, classClicked]);
        props.setSelectedClass([...res]);
      }
    },
    [props, navigate, checkSelectedClasses]
  );

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objKey: string) => {
      const isChecked = event.target.checked;
      const value = event.target.id;

      if (isChecked) {
        if (!classFilter.current[objKey]) {
          classFilter.current[objKey] = [value];
          return;
        }

        classFilter.current[objKey].push(value);
      } else {
        if (!classFilter.current[objKey]) return;
        const filtered = classFilter.current[objKey].filter((foo: string) => foo !== value);
        classFilter.current[objKey] = filtered;
      }
    },
    [classFilter]
  );

  const handleResetFilter = () => {
    classFilter.current = {};
    listClasses();
  };

  const isChecked = (objKey: string, value: string) => {
    if (!classFilter.current[objKey]) return false;
    return classFilter.current[objKey].find((foo: string) => foo === value);
  };

  return (
    <Fragment>
      <HeaderDirectory
        status="quaternary"
        title={props.headerTitle}
        pathToReturn={props.pathToReturn}
        menuKebab={{
          tooltip: { view: 'ClassDirectory' },
          menuBackHome: true,
        }}
      />
      {isLoading && <Spinner />}
      <DirectoryContainer footer={true}>
        <WrapperContent>
          {/* START FILTER */}
          <AdvancedFilter
            onChangeMainInput={(newValue) => setInputSearch(newValue)}
            applyFilterButtonBackground="#855CBD"
            handleResetFilter={handleResetFilter}
            handleApplyFilter={listClasses}
          >
            <Label>Grupo Etário:</Label>
            <section className="checkbox-section">
              {AGE_GROUP_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'age_groups');
                  }}
                  defaultChecked={isChecked('age_groups', ag.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Status:</Label>
            <section className="checkbox-section">
              {CLASS_STATUS_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'status');
                  }}
                  defaultChecked={isChecked('status', ag.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Tipo:</Label>
            <section className="checkbox-section">
              {CLASS_TYPE_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'types');
                  }}
                  defaultChecked={isChecked('types', ag.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Modalidade:</Label>
            <section className="checkbox-section">
              {CLASS_MODALITY_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'modalities');
                  }}
                  defaultChecked={isChecked('modalities', ag.value)}
                />
              ))}
            </section>
          </AdvancedFilter>
          {/* END FILTER */}

          <div className="container-all-classes">
            {classes.map((c) => (
              <ClassCard
                handleOnClick={handleClickClassCard}
                canSelect={!!props.canSelect}
                classesSelected={props.selectedClass}
                classInfo={c}
                key={c.id}
              />
            ))}
          </div>

          {!!classes.length && (
            <Pagination
              totalOfPages={totalOfPages}
              currentPage={currentPage}
              callbackGetListData={(_, page) => listClasses(page)}
              status="quaternary"
            />
          )}
        </WrapperContent>

        <PageFooter>
          <Button transform="none" status="quaternary" shaded onClick={handleClickBtn}>
            {props.btnText}
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Fragment>
  );
};

export default ClassesList;
